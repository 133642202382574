body {
  /* background-image: url(../assets/vid/videoBg.mp4) center #FFFFFF !important; */
  font-family: 'Montserrat', sans-serif;
}

h1 {
    animation: tp-rotateplane 1.2s infinite ease-in-out;
}

.text1 {
    /* animation-name; */
    animation-duration: 10s;
    /* animation-delay: 2s; */
    animation: fadeIn 5s;
    
    transition: 1s;
    -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.left-to-right {
  animation: 3s left-to-right ease-in-out;
  -webkit-animation: left-to-right 3s;
  -moz-animation: left-to-right 3s;
  -o-animation: left-to-right 3s;
  -ms-animation: left-to-right 3s;
}

.right-to-left {
  animation: 3s right-to-left ease-in-out;
  -webkit-animation: right-to-left 3s;
  -moz-animation: right-to-left 3s;
  -o-animation: right-to-left 3s;
  -ms-animation: right-to-left 3s;
}

@keyframes left-to-right {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  70% {
    opacity: 1;
    transform: translateX(0%);
  }
  85% { 
    opacity: 1;
    transform: translateX(0%);
  }
  90% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes right-to-left {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }
  70% {
    opacity: 1;
    transform: translateX(0%);
  }
  85% {
    opacity: 1;
    transform: translateX(0%);
  }
  90% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.gradient-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #fff !important;
  text-transform: capitalize;
  font-size: 15px;
  background: rgb(19,175,240);
  background: linear-gradient(105deg, rgba(91,104,235,1) 0%, rgba(40,225,253,1) 100%);
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all .5s;
}

.gradient-button a:hover {
  color: #fff !important;
  background: rgb(19,175,240);
  background: linear-gradient(105deg, rgba(91,104,235,1) 0%, rgba(40,225,253,1) 100%);
}

.cta {
  display: flex;
  padding: 10px 45px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  color: white;
  background: #6225E6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
}

.cta:focus {
 outline: none; 
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #276bc4;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover  span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

#span span {
  transform: skewX(15deg) 
}

#span span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
      fill: white;
  }
  50% {
      fill: #276bc4;
  }
  100% {
      fill: white;
  }
}

@import url('https://fonts.googleapis.com/css?family=Poppins:900i');
.wrapper {
  display: flex;
  justify-content: center;
}



#personal {
  color:white;
  text-decoration:none;
  position:absolute;
  bottom:15px;
  right:2%;
}
/*    start code for the actual button:         */


/*   
    Spot is the span on the inside of the href that
    fills the parent and makes the hover and link work
    for the entire div
*/

.spot {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
/*
    This is the outer svg wrapper that the SVG itself will 
    fill. Multiple svg-wrapper classes can be put side by side.
*/

.svg-wrapper {
  margin-top: 0;
  position: relative;
  width: 150px;
  /*make sure to use same height/width as in the html*/
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px
}
/*
  This is where we define the fill, color, thickness,
  and stroke pattern of the SVG when there is no hover.
  The dasharray and offset together define the line position
  under the words. Here's also where the transition speed is set.
*/

#shape {
  stroke-width: 6px;
  fill: transparent;
  stroke: #009FFD;
  stroke-dasharray: 85 400;
  stroke-dashoffset: -220;
  transition: 1s all ease;
}
/* 
    Pushing the text up into the SVG. Without this the
    text would be below the div's.
*/

#text {
  margin-top: -35px;
  text-align: center;
}

#text a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 100;
  font-size: 1.1em;
}
/* 
    Changing the shape on hover. I change the color of the stroke,
make it thinner, then set it to again wrap around the entire parent element.
*/

.svg-wrapper:hover #shape {
  stroke-dasharray: 50 0;
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke: rgb(19,175,240);
}


/* @media screen and (max-width: 320px){
  
} */



.h11{
  color: #484848;
  font-size: 50px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 7px;
  cursor: pointer
}
.h11 span{
  transition: .5s linear
}
.h11:hover span:nth-child(1){
  margin-right: 5px
}
.h11:hover span:nth-child(1):after{
  content: "";
}
.h11:hover span:nth-child(2){
  margin-left: 30px
}
.h11:hover span{
  color: #fff;
  text-shadow: 0 0 10px #fff,
               0 0 20px #fff, 
               0 0 40px #fff;
}

.h22:hover span{
  color: #000;
  text-shadow: 0 0 10px #fff,
               0 0 20px #fff, 
               0 0 40px #fff;
}